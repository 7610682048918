<template>
<div class="hello">
    <div class="">

        <main>

            <!-- Features end -->
            <!-- Testimonials start -->
            <section id="" class="pad-l">
                <div class="container">
                    <br>
                    <div id="parent">

                        <!--
  <span v-hotkey="keymap" v-show="show"> 
    Press `ctrl + esc` to toggle me! Hold `enter` to hide me!
  </span>
-->

                        <div id="container">
                            <div class="result" v-if="showScore">
                                <h2>Test Results: {{score}}/{{noQuestions}}</h2>
                                <hr noshade>
                                <div v-for="(d,index) in history" :key="history[index].question">

                                    <span>
                                        <span :class=history[index].valid>{{ history[index].valid }}</span> {{ history[index].question }} ({{ history[index].solution }})
                                    </span>

                                </div>

                                <div class="buttons" v-if="this.score < this.data.length">
                                    <span class="btn-reset" v-on:click="index=0; showScore=false; history = []; score = 0;">Reset</span>
                                    <a :href="'/practice/' + this.$route.params.topic + '/' + this.$route.params.id"><span class="btn-blue">Pratice</span></a>
                                </div>

                                <div class="buttons" v-if="this.score === this.data.length">
                                    <a v-if="this.$route.params.id < (this.lessons-1)" :href="'/test/' + this.$route.params.topic + '/' + (Number(this.$route.params.id)+1)"> <span class="btn-blue">Next</span> </a>&nbsp;
                                    <a :href="'/overview/' + this.$route.params.topic"> <span class="btn-blue">Overview</span> </a>
                                </div>

                            </div>
                        </div>

                        <div id="container" v-if="!showScore">
                            <div id="left">
                                &nbsp;
                            </div>
                            <div id="center" v-if="this.data[index]">

                                <h2>
                                    {{this.data[index].question}} ({{this.index}}/{{this.noQuestions}})
                                </h2><br>

                                <input id="ui-key-input" v-model="message" @keydown.esc="escFn" @keydown="keypress" placeholder="">
                                <br><br>

                                <div v-for="(item,index) in history" :key="item.id">
                                    <div class="past" v-if="index < 3">
                                        <div :class=item.valid>{{ item.solution }}</div><br>
                                        <div>{{ item.question }}</div>
                                    </div>
                                </div>

                            </div>
                            <br>
                        </div>
                    </div>

                </div>
            </section>
            <!-- Testimonials end -->

        </main>


    </div>
</div>
</template>

<script>
//import router from '../router'

import keyboard from '../assets/js/keyboard.js'

export default {
    data() {
        return {
            show: true,
            message: '',
            index: 0,
            data: [],
            history: [],
            hideAnswers: true,
            showScore: false,
            noQuestions: 0,
            score: 0,
            lessons: 0
        }
    },
    created: function () {
        this.getLessons();
        this.getSupaData();

        if (this.$route.params.id > 0) {
            var key = this.$cookie.get('key');
            if (key === null) {
                //router.replace("/login");
                window.location.href = 'https://gum.co/eHhaGG';
            }
        }

    },
    methods: {
        keypress(e) {
           keyboard.keypress(e);

           if (keyboard.message.length >= 1) {
             this.message = keyboard.message;
           }
        },

        escFn() {
            this.message = "ESC";
        },
        async getSupaData() {
            this.routeid = this.$route.params.id;

            fetch('../../../api/json/' + this.$route.params.topic + '.json')
                .then(response => response.json())
                .then(data => (this.data = data ))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    this.response = response;

                    //console.log(this.routeid);
                    //console.log(this.$route.params.id);
                    this.data = this.data.filter((v) => {
                       return v.level <= Number(this.$route.params.id)+1 
                    })
                    console.log(this.data);
                    this.noQuestions = this.data.length;
                });
        },
        async getLessons() {
        },

        showResult() {
            // test complete
            if (this.index >= this.noQuestions) {
                this.showScore = true;
                var level = parseInt(this.$route.params.id) + 1;
                console.log('id = ' + level + ' ' + this.$route.params.topic);

                if (this.score >= this.noQuestions - 1) {
                    this.$cookie.delete(this.$route.params.topic);
                    this.$cookie.set(this.$route.params.topic, level);
                }
            }
        }
    },
    computed: {},
    watch: {
        'message': function (val) {
            console.log(val);

            this.showResult();

            for (var i = 0; i < this.data.length; i++) {
                if (this.data[i].solution === '') {
                    console.log('ERROR IN JSON DATA - ERROR IN JSON DATA - ERROR IN JSON DATA - ERROR IN JSON DATA');
                }
            }

            // convert to string if type if number
            if (typeof(this.data[this.index].solution) === 'number') {
                this.data[this.index].solution = this.data[this.index].solution.toString();
            }

            if (this.message === this.data[this.index].solution) {
                if (val != '') {
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'true',
                        'question': this.data[this.index].question,
                        'id': Math.random()
                    });
                    this.message = '';
                    this.index = this.index + 1;
                    this.score = this.score + 1;
                    console.log('score = ' + this.score);
                }
            } else if (this.data[this.index].solution.length === 1) {
                if (val != '') {
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'false',
                        'question': this.data[this.index].question,
                        'id': Math.random()
                    });
                    this.message = '';
                    this.index = this.index + 1;
                }
            } else {
                // answer longer than one 
                if (this.message.length === this.data[this.index].solution.length) {

                    //if (this.message === this.data[this.index].solution) 
                    //  this.score = this.score + 1;

                    if (val != '') {
                        this.history.unshift({
                            'solution': this.message,
                            'valid': 'false',
                            'question': this.data[this.index].question,
                            'id': Math.random()
                        });
                        this.message = '';
                    }
                    this.index += 1;
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.result {
    background: white;
    text-align: left;
    padding: 32px;
    box-shadow: 4px 3px 8px 1px #969696;
    -webkit-box-shadow: 4px 3px 8px 1px #969696;
}

.buttons {
    margin-top: 12px;
}

.btn-reset {
    background: rgb(204, 22, 22);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 2em;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    padding: 16px 10px;
    border-radius: 5px;
    margin-right: 5px;
}

.btn-blue {
    background: rgb(0, 51, 159);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 2em;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    padding: 16px 10px;
    border-radius: 5px;
}

/* left, center, right */

#container {
    width: 100%;
}


@media screen and (max-width : 1024px){
    #center {
        width: 100%;
    }

    #ui-key-input {
        width: 80%;
    }
}

@media screen and (min-width : 1024px){
    #left {
        float: left;
        width: 250px;
        text-align: right;
    }

    #right {
        float: right;
        width: 100px;
    }

    #center {
        width: 640px;
        margin: 0 auto;
    }
}

/* end left,center, right*/

/* slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* end slider */

.true {
    color: green;
}

.false {
    color: red;
}

.past {
    font-size: 24px;
    text-align: left;
    padding-top: 14px;
    position: relative;
    background-color: white;
    margin: 0 auto;
    padding-bottom: 14px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    margin-bottom: 20px;
    animation: mymove 0.1s;
    z-index: 1;
}

@keyframes mymove {
    from {
        top: -70px;
    }

    to {
        top: 0px;
    }
}


@media screen and (max-width : 1024px){
    #ui-key-input {
        font-size: 24px;
        text-align: left;
        padding-top: 14px;
        position: relative;
        background-color: white;
        padding-bottom: 14px;
        padding-left: 14px;
        margin: 0 auto;
        border-radius: 6px;
        border: 1px solid gray;
        z-index: 2;
        width: 80%;
    }
    .past {
        width: 80%;
    }
}

@media screen and (min-width : 1024px){
    #ui-key-input {
        font-size: 24px;
        text-align: left;
        padding-top: 14px;
        position: relative;
        background-color: white;
        padding-bottom: 14px;
        padding-left: 14px;
        margin: 0 auto;
        border-radius: 6px;
        border: 1px solid gray;
        z-index: 2;
        width: 538px;
    }
    .past {
        width: 538px;
    }
}


.plan-item:nth-child(1),
.plan-item:nth-child(3) {
    border: 2px solid rgba(0, 0, 0, 0.1);
}

#pricing {
    color: black;
}

.kbc-button {
    padding: 6px;
    margin-top: 10px;
}

.btn_reset {
    background: white;
    opacity: 1;
    font-size: 1.6rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.8rem 2.6rem;
    transition: transform 100ms ease-in-out;
    border-radius: 3px;
    color: var(--text-dark);
    display: inline-block;
    text-align: center;
    border: 1px solid #dadce0;
    color: #000;
    border: 1px solid black;
    flex: 1 1 auto;
}

.btn {
    font-size: 1.125em;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.025rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.btn:hover {
    color: white;
}

h2.subtitle {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125em;
    line-height: 1.625;
    font-weight: 300;
}

.subtitle {
    color: white;
}

#parent {
    width: 100%;
    height: 400px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 8px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border-top: 4px solid #4704a1;
    border-left: 4px solid #4704a1;
}
</style>
